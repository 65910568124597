import {CKEditorModule} from '@ckeditor/ckeditor5-angular';
import {PickerComponent} from '@ctrl/ngx-emoji-mart';
import * as moment from 'moment';
import {ImageCropperModule} from 'ngx-image-cropper';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {NgxMaskModule} from 'ngx-mask';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {TourMatMenuModule} from 'ngx-ui-tour-md-menu';

import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {Injectable, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatRippleModule, NativeDateAdapter} from '@angular/material/core';
import {RouterModule} from '@angular/router';

import {CompanyMenuAvatarComponent} from '../../components/company-menu-avatar/company-menu-avatar.component';
import {AlphbetDirective} from '../../utils/alphbet.directive';
import {AutoFocusDirective} from '../../utils/auto-focus.directive';
import {DateMaskDirective} from '../../utils/date-mask.directive';
import {HighlightPipe} from '../../utils/highlight.pipe';
import {LinkifyPipe} from '../../utils/linkify.pipe';
import {NoScrollInputDirective} from '../../utils/no-scroll-input.directive';
import {FormatDatePipe} from '../../utils/pipes/formatDate.pipe';
import {PhoneNumberPipe} from '../../utils/pipes/phoneNumber.pipe';
import {SortByIdPipe} from '../../utils/pipes/sortByIdPipe';
import {StripHtmlPipe} from '../../utils/pipes/strip-html.pipe';
import {TagifyPipe} from '../../utils/tagify.pipe';
import {TextareaAutoresizeDirective} from '../task-manager/textarea-autoresize.directive';
import {AdminsDialogComponent} from './components/admins-dialog/admins-dialog.component';
import {AssessmentComponent} from './components/assessment/assessment.component';
import {BetaComponent} from './components/beta/beta.component';
import {BoardsForMeetListComponent} from './components/boards-for-meet/boards-list/boards-list.component';
import {BoardsForMeetComponent} from './components/boards-for-meet/boards.component';
import {BoardsAddComponent} from './components/boards/boards-add/boards-add.component';
import {BoardsListComponent} from './components/boards/boards-list/boards-list.component';
import {BoardsComponent} from './components/boards/boards.component';
import {ChatMembersMenuComponent} from './components/chat-members-menu/chat-members-menu.component';
import {ChatMenuComponent} from './components/chat-menu/chat-menu.component';
import {ChatWithAdminComponent} from './components/chat-with-admin/chat-with-admin.component';
import {ChatAudioComponent} from './components/chat/chat-audio/chat-audio.component';
import {ChatMembersComponent} from './components/chat/chat-members/chat-members.component';
import {ChatMsgComponent} from './components/chat/chat-msg/chat-msg.component';
import {ChatUsersLinkComponent} from './components/chat/chat-users-link/chat-users-link.component';
import {ChatComponent, FocusDirective,} from './components/chat/chat.component';
import {EmptyListHelperComponent} from './components/company/empty-list-helper/empty-list-helper.component';
import {SlotComponent} from './components/company/slot/slot.component';
import {SubdivisionListItemComponent} from './components/company/subdivision-list-item/subdivision-list-item.component';
import {SubdivisionListComponent} from './components/company/subdivision-list/subdivision-list.component';
import {ConfirmSmartComponent} from './components/confirm-smart/confirm-smart.component';
import {ConfirmComponent} from './components/confirm/confirm.component';
import {FileEditFormComponent} from './components/fileupload/file-edit-form/file-edit-form.component';
import {FileuploadEditFormComponent} from './components/fileupload/fileupload-edit-form/fileupload-edit-form.component';
import {DndDirective, FileuploadFormComponent,} from './components/fileupload/fileupload-form/fileupload-form.component';
import {FileuploadComponent} from './components/fileupload/fileupload.component';
import {LinkEditFormComponent} from './components/fileupload/link-edit-form/link-edit-form.component';
import {GroupListItemComponent} from './components/groups/group-list-item/group-list-item.component';
import {GroupSelectComponent} from './components/groups/group-select/group-select.component';
import {GroupComponent} from './components/groups/group/group.component';
import {GroupsFormComponent} from './components/groups/groups-form/groups-form.component';
import {GroupListComponent} from './components/groups/groups-list/groups-list.component';
import {GroupsComponent} from './components/groups/groups.component';
import {HeaderNavComponent} from './components/header-nav/header-nav.component';
import {HistoryPhonebookComponent} from './components/history-phonebook/history-phonebook.component';
import {HistoryComponent} from './components/history/history.component';
import {KanbanItemComponent} from './components/kanban-item-component/kanban-item-component';
import {BadgeComponent} from './components/kit/badge/badge.component';
import {ButtonComponent} from './components/kit/button/button.component';
import {IconButtonComponent} from './components/kit/icon-button/icon-button.component';
import {InnSelectComponent} from './components/kit/inn-select/inn-select.component';
import {InputComponent} from './components/kit/input/input.component';
import {ModalButtonComponent} from './components/kit/modal-button/modal-button.component';
import {AccountsComponent} from './components/kit/personal-card-edit/accounts/accounts.component';
import {ImageCropperDialogComponent} from './components/kit/personal-card-edit/image-cropper-dialog/image-cropper-dialog.component';
import {MessengersComponent} from './components/kit/personal-card-edit/messengers/messengers.component';
import {PersonalCardEditComponent} from './components/kit/personal-card-edit/personal-card-edit.component';
import {PersonalCardFormComponent} from './components/kit/personal-card-edit/personal-card-form/personal-card-form.component';
import {PhonesComponent} from './components/kit/personal-card-edit/phones/phones.component';
import {PositionAddComponent} from './components/kit/personal-card-edit/position-add/position-add.component';
import {SubdivisionAddModalComponent} from './components/kit/personal-card-edit/subdivision-edit/subdivision-add-modal/subdivision-add-modal.component';
import {SubdivisionEditComponent} from './components/kit/personal-card-edit/subdivision-edit/subdivision-edit.component';
import {PersonalPositionSlotComponent} from './components/kit/personal-position-slot/personal-position-slot.component';
import {SelectComponent} from './components/kit/select/select.component';
import {StatusSelectTplComponent} from './components/kit/status-select-tpl/status-select-tpl.component';
import {StatusSelectComponent} from './components/kit/status-select/status-select.component';
import {VerifyPhoneComponent} from './components/kit/verify-phone/verify-phone.component';
import {MotivCardComponent} from './components/motiv-card/motiv-card.component';
import {MotivationsChatComponent} from './components/motivations-chat/motivations-chat.component';
import {LearningTasksComponent} from './components/onboarding-wnd/learning-tasks/learning-tasks.component';
import {LearningComponent} from './components/onboarding-wnd/learning/learning.component';
import {OnboardingCompanyComponent} from './components/onboarding-wnd/onboarding-company/onboarding-company.component';
import {OnboardingMainComponent} from './components/onboarding-wnd/onboarding-main/onboarding-main.component';
import {OnboardingTasksComponent} from './components/onboarding-wnd/onboarding-tasks/onboarding-tasks.component';
import {OnboardingUsersComponent} from './components/onboarding-wnd/onboarding-users/onboarding-users.component';
import {OnboardingWndCommonComponent} from './components/onboarding-wnd/onboarding-wnd-common/onboarding-wnd-common.component';
import {OnboardingWndComponent} from './components/onboarding-wnd/onboarding-wnd.component';
import {SkipConfirmDialogComponent} from './components/onboarding-wnd/skip-confirm-dialog/skip-confirm-dialog.component';
import {PeopleAddComponent} from './components/people-add/people-add.component';
import {PersonItemComponent} from './components/people-add/person-item/person-item.component';
import {PersonCardComponent} from './components/person-card/person-card.component';
import {PhotoGalleryComponent} from './components/photo-gallery/photo-gallery.component';
import {ProgressBapComponent} from './components/progress-bap/progress-bap.component';
import {PromptSmartComponent} from './components/prompt-smart/prompt-smart.component';
import {QuestionAnswerComponent} from './components/question-answer/question-answer.component';
import {QuestionCardComponent} from './components/question-card/question-card.component';
import {RatingNumberComponent} from './components/rating-number/rating-number.component';
import {SearchInputComponent} from './components/search-input/search-input.component';
import {SearchMatComponent} from './components/search-mat/search-mat.component';
import {SearchComponent} from './components/search/search.component';
import {ShortFormAddComponent} from './components/short-form-add/short-form-add.component';
import {SlotsListItemComponent} from './components/slots-list-item/slots-list-item.component';
import {SmallGroupComponent} from './components/small-group/small-group.component';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {StarsComponent} from './components/stars/stars.component';
import {TagsFormComponent} from './components/tags/tags-form/tags-form.component';
import {TagsListShortComponent} from './components/tags/tags-list-short/tags-list-short.component';
import {TagsListComponent} from './components/tags/tags-list/tags-list.component';
import {TagsSelectComponent} from './components/tags/tags-select/tags-select.component';
import {TagsComponent} from './components/tags/tags.component';
import {TaskItemComponent} from './components/task-item/task-item.component';
import {TaskManagerStatusComponent} from './components/task-manager-status/task-manager-status.component';
import {TopMessageComponent} from './components/top-message/top-message.component';
import {UserAvatarComponent} from './components/user-avatar/user-avatar.component';
import {UserListItemComponent} from './components/user-list-item/user-list-item.component';
import {UserComponent} from './components/user/user.component';
import {UsersAccordionComponent} from './components/users-accordion/users-accordion.component';
import {UsersListItemComponent} from './components/users-list-item/users-list-item.component';
import {UsersListComponent} from './components/users-list/users-list.component';
import {MaterialModule} from './material.module';
import {PointReplacerPipe} from './pipes/point-replacer.pipe';
import {SafeUrlPipe} from './pipes/safe-url.pipe';
import {InfoCircleComponent} from './components/landing/info-circle/info-circle.component';
import {OverlayscrollbarsModule} from 'overlayscrollbars-ngx';
import {PendingChangesGuard} from '../calendar/utils/pending-changes.guard';
import {ChatReplyComponent} from './components/chat/chat-reply/chat-reply.component';
import {ButtonsKBComponent} from './components/fileupload/buttons-kb/buttons-kb.component';
import {SelectedFilterButtonComponent} from './components/selected-filter-button/selected-filter-button.component';
import {InputTimeComponent} from './components/input-time/input-time.component';
import {SortRangesByMin} from '../../utils/pipes/sortRangesByMin';
import {SelectUserDialogCheckboxComponent} from './components/select-user-dialog-checkbox/select-user-dialog-checkbox.component';
import {StickyHeaderComponent} from './components/sticky-header/sticky-header.component';
import {InViewportModule} from 'ng-in-viewport';
import {PopoverModule} from '@easiangular/ngx-smart-popover';

/**
 * Custom date adapter for material datePicker
 */
@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
    getFirstDayOfWeek(): number {
        return 1;
    }

    parse(value: any): Date | null {
        return moment(value, 'DD-MM-YYYY').toDate();
    }
}

const components = [
    SpinnerComponent,
    ChatComponent,
    ChatMenuComponent,
    ChatMsgComponent,
    ChatMembersComponent,
    SearchInputComponent,
    ConfirmComponent,
    ConfirmSmartComponent,
    SearchComponent,
    PersonCardComponent,
    PhotoGalleryComponent,
    SearchMatComponent,
    ButtonComponent,
    ModalButtonComponent,
    InputComponent,
    SelectComponent,
    IconButtonComponent,
    BadgeComponent,
    PeopleAddComponent,
    PersonItemComponent,
    PersonalCardEditComponent,
    PhonesComponent,
    AccountsComponent,
    MessengersComponent,
    SubdivisionEditComponent,
    PersonalCardFormComponent,
    PositionAddComponent,
    StarsComponent,
    RatingNumberComponent,
    HistoryComponent,
    HistoryPhonebookComponent,
    PromptSmartComponent,
    StatusSelectComponent,
    StatusSelectTplComponent,
    OnboardingWndComponent,
    HeaderNavComponent,
    InnSelectComponent,
    CompanyMenuAvatarComponent,
    VerifyPhoneComponent,
    UsersAccordionComponent,
    UserListItemComponent,
    BetaComponent,
    FileuploadComponent,
    FileuploadFormComponent,
    FileuploadEditFormComponent,
    LinkEditFormComponent,
    FileEditFormComponent,
    PersonalPositionSlotComponent,
    SubdivisionListItemComponent,
    SubdivisionListComponent,
    EmptyListHelperComponent,
    SlotComponent,
    SlotsListItemComponent,
    TaskManagerStatusComponent,
    OnboardingWndCommonComponent,
    OnboardingCompanyComponent,
    OnboardingUsersComponent,
    OnboardingMainComponent,
    OnboardingTasksComponent,
    TaskItemComponent,
    ShortFormAddComponent,
    SmallGroupComponent,
    UserComponent,
    AssessmentComponent,
    QuestionCardComponent,
    QuestionAnswerComponent,
    MotivCardComponent,
    UsersListComponent,
    MotivationsChatComponent,
    UsersListItemComponent,
    BoardsComponent,
    BoardsAddComponent,
    BoardsListComponent,
    TagsFormComponent,
    TagsComponent,
    TagsListComponent,
    GroupComponent,
    GroupSelectComponent,
    GroupListComponent,
    GroupsComponent,
    GroupsFormComponent,
    TagsSelectComponent,
    GroupListItemComponent,
    TagsListShortComponent,
    TagsListShortComponent,
    BoardsForMeetListComponent,
    BoardsForMeetComponent,
    ChatWithAdminComponent,
    AdminsDialogComponent,
    SkipConfirmDialogComponent,
    ProgressBapComponent,
    BoardsForMeetListComponent,
    BoardsForMeetComponent,
    ChatWithAdminComponent,
    AdminsDialogComponent,
    SkipConfirmDialogComponent,
    LearningTasksComponent,
    LearningComponent,
    KanbanItemComponent,
    ChatMembersMenuComponent,
    ChatUsersLinkComponent,
    SubdivisionAddModalComponent,
    ImageCropperDialogComponent,
    TopMessageComponent,
    UserAvatarComponent,
    ChatAudioComponent,
    InfoCircleComponent,
    ChatReplyComponent,
    ButtonsKBComponent,
    InfoCircleComponent,
    ChatReplyComponent,
    SelectedFilterButtonComponent,
    InputTimeComponent,
    SelectUserDialogCheckboxComponent,
    StickyHeaderComponent,
];

const pipes = [
    LinkifyPipe,
    TagifyPipe,
    HighlightPipe,
    PhoneNumberPipe,
    PointReplacerPipe,
    SafeUrlPipe,
    FormatDatePipe,
    SortByIdPipe,
    StripHtmlPipe,
    SortRangesByMin,

];
const directives = [
    AutoFocusDirective,
    FocusDirective,
    DateMaskDirective,
    AlphbetDirective,
    DndDirective,
    NoScrollInputDirective,
    TextareaAutoresizeDirective,
];
const modules = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    InfiniteScrollModule,
    NgxMatSelectSearchModule,
    MaterialModule,
    CKEditorModule,
    ImageCropperModule,
    TourMatMenuModule,
    InViewportModule,
    PopoverModule
];

@NgModule({
    declarations: [...components, ...pipes, ...directives],
    imports: [
        ...modules,
        NgxMaskModule,
        CarouselModule,
        RouterModule,
        OverlayModule,
        PickerComponent,
        OverlayscrollbarsModule,
        MatRippleModule,
    ],
    exports: [...components, ...pipes, ...directives, ...modules],
    providers: [
        PendingChangesGuard,
    ]
})
export class SharedModule {}
