import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TasksService} from 'src/app/modules/task-manager/services/tasks.service';
import {Component, Directive, ElementRef, EventEmitter, HostBinding, HostListener, OnInit, Output, ViewChild} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Link} from 'src/app/models/link';
import {ApiBoardsService} from 'src/app/modules/task-manager/services/api-boards.service';
import {File as FileModel, FileType, getFileExtensionWidget} from '../../../../../models/file';
import {JSON} from 'ta-json';
import {ApiCabinetService} from 'src/app/modules/cabinet/services/api-cabinet.service';
import {GlobalNotificationCenterService} from 'src/app/services/global-notification-center.service';
import {SharedService} from '../../../services/shared.service';
import {debug} from 'src/app/utils/commons';

//! доработать директиву
@Directive({
    selector: '[appDnd]'
})
export class DndDirective {

    @HostBinding('fileover') fileOver = false;

    // Dragover listener
    @HostListener('dragover', ['$event']) onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = true;
    }

    // Dragleave listener
    @HostListener('dragleave', ['$event'])
    public onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.fileOver = false;
    }
}

@Component({
    selector: 'app-fileupload-form',
    templateUrl: './fileupload-form.component.html',
    styleUrls: ['./fileupload-form.component.scss']
})
export class FileuploadFormComponent implements OnInit {

    @Output() public readonly filesChange: EventEmitter<any> = new EventEmitter();
    @Output() public readonly linkAdd: EventEmitter<any> = new EventEmitter();

    @ViewChild('link') link: ElementRef;
    @ViewChild('name') linkName: ElementRef;

    uploadImagesAsFiles = false;

    // Массив файлов с метаинфой
    public files = [];
    // Массив ссылок
    public links = [];
    // Загрузки
    public process = [];
    // Каунтер ошибок
    public errors = [];
    // gmail пользователя
    public gmail;
    // gmail компании
    public gmailCompany;
    public isLoadedKB = true;
    public isLoadedSubmit = true;
    public isLoadedKBLinks = true;
    public itemId = 0;
    // public downloadFiles = [];
    public downloadLinks = [];

    public formGroup: UntypedFormGroup;

    private readonly GOOGLE_DOC_LINK = 'https://docs.google.com/document/d/';
    private readonly GOOGLE_PR_LINK = 'https://docs.google.com/presentation/d/';
    private readonly GOOGLE_SS_LINK = 'https://docs.google.com/spreadsheets/d/';
    private readonly GOOGLE_FILE_LINK = 'https://drive.google.com/file/d/';

    private readonly mimeTypes = [
        'application/pdf',

        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/ms-doc',
        'application/msword',

        'application/pdf, application/msword',
        'text/plain',
        'application/vnd.google-apps.document',

        'application/mspowerpoint',
        'application/powerpoint',
        'application/vnd.ms-powerpoint',
        'application/x-mspowerpoint',
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',

        'application/vnd.google-apps.presentation',
        'application/excel',
        'application/vnd.ms-excel',
        'application/x-excel',
        'application/x-msexcel',

        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.google-apps.spreadsheet',

        'application/octet-stream',
        'text/csv',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

        'application/vnd.google-apps.folder',
        'application/ppt',
    ];

    // Триггер смерти компонента
    private destroyed = new Subject<void>();
    private appendUploadEvent$ = new Subject();

    constructor(
        private api: ApiBoardsService,
        private apiCabinet: ApiCabinetService,
        private tasksService: TasksService,
        public dialogRef: MatDialogRef<any>,
        private notiService: GlobalNotificationCenterService,
        private sharedService: SharedService,
    ) {
    }

    get isLearningOpen() {
        return this.sharedService.openTaskLearning$.value;
    }

    get gDocCondition() {
        return this.gmailCompany && this.gmail;
    }

    get docCount() {
        return this.files.filter(el => this.isDoc(el.file)).length;
    }

    get fileCount() {
        return this.files.filter(el => !this.isDoc(el.file)).length;
    }

    //@HostListener('window:paste', ['$event']) // or use this instead of (paste)
    public onPaste($event: ClipboardEvent): void {
        const clipboardData = $event.clipboardData || (window as any).clipboardData;

        if (clipboardData.files.length > 0) {
            this.appendUploadEvent$.next(clipboardData.files)
        }
    }

    onFocusChange(index, item) {
        if (this.formGroup.controls[item.id].value) {
            if (this.files[index].file) {

                const newName = this.formGroup.controls[item.id].value;
                const dot = this.files[index].file.name.lastIndexOf('.');
                const type = this.files[index].file.name.slice(dot, this.files[index].file.name.length);

                const newDot = newName.lastIndexOf('.');
                let newVal = '';
                if (newDot >= 0) {
                    newVal = newName.slice(0, newDot) + type;
                } else {
                    newVal = newName + type;
                }

                this.formGroup.controls[item.id].setValue(newVal);

                // у файла имя только как геттер, поэтому я создаю новый файл с другим именем.
                const myNewFile = new File(
                    [this.files[index].file],
                    this.formGroup.controls[item.id].value.trim(),
                    {type: this.files[index].file.type}
                );
                this.files[index].file = myNewFile;
            }
        }
    }

    ngOnInit() {
        this.formGroup = new UntypedFormGroup({});
        let storage;
        const info = localStorage.getItem('currentUser.ApiAuth');
        if (info) {
            storage = JSON.parse(info);
            this.gmail = storage.accounts.find(el => el.is_gmail === true);
            const companyId = storage.company.id;
            this.getCompany(companyId);
        }

        this.tasksService.addToKB$
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                this.addToKB(res);
            });

        this.sharedService.learningBoardName$
            .pipe(takeUntil(this.destroyed))
            .subscribe(res => {
                if (res && res === 'Вложение') {
                    this.link.nativeElement.value = 'https://youtu.be/dQw4w9WgXcQ?si=bkfBXeV2D1KJ4tNK';
                    this.linkName.nativeElement.value = res;
                }
                if (res && res === 'Вложение повтор') {
                    this.addLink('https://youtu.be/dQw4w9WgXcQ?si=bkfBXeV2D1KJ4tNK', 'Вложение');
                }
            });

        this.appendUploadEvent$
            .pipe(takeUntil(this.destroyed), debounceTime(100))
            .subscribe(res => {
                this.appendUpload(res);
            });

        setTimeout(() => {
            this.link.nativeElement.focus();
        }, 100);
    }

    addToKB({index, addKB, conversion}) {
        if (!addKB) {
            this.files[index].KBData = null;
        } else {
            this.files[index].KBData = ({conversion});
        }
    }

    appendUpload(files) {
        for (const file of files) {
            if (file.size === 0) {
                this.errors.push({message: 'Файл не должен быть пустым'});
                setTimeout(() => {
                    this.errors.pop();
                }, 4000);
            } else if (file.size > 104857600) {
                this.errors.push({message: 'Размер файла превышает допустимые 100 МБ'});
                setTimeout(() => {
                    this.errors.pop();
                }, 4000);
            } else {
                const size = this.formatBytes(file.size, 2);
                this.formGroup.addControl(this.itemId.toString(), new UntypedFormControl(file.name, [Validators.required]));
                this.files.push({file, KBData: null, id: this.itemId, size});
                this.itemId++;
            }
        }
    }

    getCompany(companyId) {
        this.apiCabinet
            .getCompanyProfile(companyId)
            .pipe(takeUntil(this.destroyed))
            .subscribe(
                (res) => {
                    this.gmailCompany = res.gmail;

                    setTimeout(() => {
                        this.link.nativeElement.focus();
                    }, 100);
                },
                (err) => {
                    this.gmailCompany = null;
                }
            );
    }

    deleteFile(index) {
        this.formGroup.removeControl(this.files[index].id.toString());
        this.files.splice(index, 1);
    }

    deleteLink(index) {
        this.links.splice(index, 1);
    }

    async onChangeFile($event) {
        console.log($event.target.files);
        if ($event.target.files) {
            this.appendUpload($event.target.files);
        }
    }

    addLink(link, name) {
        link = link.trim();
        if (!link.length) {
            return false;
        }

        const params = {
            link: {
                name,
                url: link
            },
            flagKB: false
        };
        this.links.push(params);
    }

    isImage(item) {
        return item.type === FileType.IMAGE;
    }

    isDoc(item) {
        for (const mimeType of this.mimeTypes) {
            if (mimeType === item.type) {
                return true;
            }
        }

        return false;
    }

    getFileUrl(item) {
        return '/assets/file_extension/' + getFileExtensionWidget(item.name) + '.png';
    }

    public close() {
        this.dialogRef.close();
    }

    public getLink(link: string) {
        if (link.includes(this.GOOGLE_DOC_LINK)) {
            return 'radius_google_doc';
        } else if (link.includes(this.GOOGLE_PR_LINK)) {
            return 'radius_google_presentation';
        } else if (link.includes(this.GOOGLE_SS_LINK)) {
            return 'radius_google_spreadsheet';
        } else {
            return 'radius_link';
        }
    }

    public save() {
        const controls = this.formGroup.controls;
        for (const name in controls) {
            if (controls[name].invalid || controls[name].value.trim().length === 0) {
                this.notiService.handleError('Нельзя сохранять файлы без названия');
                return;
            }
        }
        if (this.files.length > 0) {
            const submitFiles: FileModel[] = [];
            let counter = 0;
            this.isLoadedKB = false;
            this.files.forEach(element => {
                if (element.KBData) {
                    const uploadData = new FormData();
                    uploadData.append('file', element.file);
                    if (element.KBData.conversion === false) {
                        uploadData.append('ignore_conversion', 'true');
                    }
                    this.api.createFileKB(uploadData)
                        .pipe(takeUntil(this.destroyed))
                        .subscribe(res => {
                            let newLink;
                            switch (res.type) {
                                case 'spreadsheet':
                                    newLink = this.GOOGLE_SS_LINK + res.entity_id;
                                    break;
                                case 'presentation':
                                    newLink = this.GOOGLE_PR_LINK + res.entity_id;
                                    break;
                                case 'document':
                                    newLink = this.GOOGLE_DOC_LINK + res.entity_id;
                                    break;
                                default:
                                    newLink = this.GOOGLE_FILE_LINK + res.entity_id;
                            }
                            this.downloadLinks.push({url: newLink, name: res.name});
                            counter++;
                            if (counter === this.files.length) {
                                this.isLoadedKB = true;
                                this.saveStep2(submitFiles);
                            }
                        }, err => {
                            this.notiService.handleError(err);
                            counter++;
                            if (counter === this.files.length) {
                                this.isLoadedKB = true;
                                this.saveStep2(submitFiles);
                            }
                        });
                } else {
                    submitFiles.push(element.file);
                    counter++;
                    if (counter === this.files.length) {
                        this.isLoadedKB = true;
                        this.saveStep2(submitFiles);
                    }
                }
            });
        } else {
            this.saveStep2([]);
        }
    }


    saveStep2(submitFiles) {
        const files = [];
        let counter = 0;
        if (submitFiles.length > 0) {
            this.isLoadedSubmit = false;
            submitFiles.forEach(el => {
                const uploadData = new FormData();
                uploadData.append('upload', el);
                uploadData.append('entity_id', 'boards');

                if (this.uploadImagesAsFiles) {
                    uploadData.append('as_file', 'true');
                }

                this.api.uploadFile(uploadData)
                    .pipe(takeUntil(this.destroyed))
                    .subscribe(res => {
                        const file = JSON.deserialize<FileModel>(res, FileModel);
                        files.push(file);
                        counter++;
                        if (counter === submitFiles.length) {
                            this.isLoadedSubmit = true;
                            this.saveStep3(files);
                        }
                    }, err => {
                        this.notiService.handleFullError(err);
                        counter++;
                        if (counter === submitFiles.length) {
                            this.isLoadedSubmit = true;
                            this.saveStep3(files);
                        }
                    });
            });
        } else {
            this.saveStep3(files);
        }
    }

    saveStep3(files) {
        const submitLinks: Link[] = [];
        if (this.links.length > 0) {
            let counter = 0;
            this.isLoadedKBLinks = false;
            this.links.forEach(element => {
                if (element.flagKB) {
                    const uploadData = new FormData();
                    uploadData.append('external_link', element.link.url);
                    uploadData.append('external_permission', 'editor');
                    if (element.link.name) {
                        uploadData.append('blank_name', element.link.name);
                    }
                    this.api.createFileKB(uploadData)
                        .pipe(takeUntil(this.destroyed))
                        .subscribe(res => {
                            submitLinks.push(element.link);
                            counter++;
                            if (counter === this.links.length) {
                                this.isLoadedKBLinks = true;
                                this.saveStep4(files, submitLinks);
                            }
                        }, err => {
                            this.notiService.handleError('Невалидная ссылка');
                            submitLinks.push(element.link);
                            counter++;
                            if (counter === this.links.length) {
                                this.isLoadedKBLinks = true;
                                this.saveStep4(files, submitLinks);
                            }
                        });
                } else {
                    submitLinks.push(element.link);
                    counter++;
                    if (counter === this.links.length) {
                        this.isLoadedKBLinks = true;
                        this.saveStep4(files, submitLinks);
                    }
                }
            });
        } else {
            this.saveStep4(files, submitLinks);
        }
    }

    saveStep4(attachFiles, submitLinks) {
        Array.prototype.push.apply(this.downloadLinks, submitLinks);
        if (this.downloadLinks) {
            this.linkAdd.emit(this.downloadLinks);
        }
        if (attachFiles) {
            this.filesChange.emit(attachFiles);
        }
        this.dialogRef.close(true);
    }

    public saveLinkInKB(index) {
        this.links[index].flagKB = !this.links[index].flagKB;
    }

    formatBytes(bytes, decimals = 2) {
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['bytes', 'KB', 'MB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    onAddLinkButtonPressed(event: MouseEvent, link: HTMLInputElement, name: HTMLInputElement) {
        this.addLink(link.value, name.value);
        link.value = '';
        name.value = '';
    }

    onPasteButtonClick(event: MouseEvent) {

    }

    dropHandler(event: DragEvent) {
        debug(event);
        event.preventDefault();

        const dataTransfer = event.dataTransfer;
        if (dataTransfer.files.length > 0) {
            this.appendUpload(dataTransfer.files);
        }
    }

    dragOverHandler(event: DragEvent) {
        event.preventDefault();
    }

    hasFiles() {
        return this.links.length !== 0 || this.files.length !== 0;
    }

    hasErrors() {
        return this.errors.length !== 0;
    }

    hasFilesOrErrors() {
        return this.hasFiles() || this.hasErrors();
    }

    hasImages() {
        return this.files.find(file => file.file.type.indexOf('image') === 0);
    }
}
