import {NgModule} from '@angular/core';
import {chatConfig, MainComponent} from './pages/main/main.component';
import {HeaderComponent} from './components/header/header.component';
import {AppsComponent} from './components/apps/apps.component';
import {LostComponent} from './pages/lost/lost.component';
import {HorizontalSpinnerComponent} from './modules/shared/components/horizontal-spinner/horizontal-spinner.component';
import {AdsComponent} from './components/ads/ads.component';
import {EmailValidatorValueDirective} from './utils/email-validator-value.directive';
import {HttpClient} from '@angular/common/http';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {IsAuthenticatedGuard} from './utils/guards/is-authenticated.guard';
import {UserInfoService} from './services/user-info.service';
import {SharedModule} from './modules/shared/shared.module';
import {WebsocketService} from './services/webSocket.api';
import {RouterModule, Routes} from '@angular/router';
import {NgxMaskModule} from 'ngx-mask';
import {IsNpmGuard} from './utils/guards/is-npm.guard';
import {RulesComponent} from './pages/rules/rules.component';
import {TestSentryComponent} from './pages/test-sentry/test-sentry.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [IsAuthenticatedGuard],
        canActivateChild: [IsAuthenticatedGuard],
        children: [
            {path: '',
                loadChildren: () => import('./modules/chat-rooms/chat-rooms.module')
                    .then(m => m.ChatRoomsModule), outlet: 'chats'},
            {path: '',
                loadChildren: () => import('./modules/notification-center/notification-center.module')
                    .then(m => m.NotificationCenterModule), outlet: 'notifications'},
            {path: '',
                loadChildren: () => import('./modules/questions/questions.module')
                    .then(m => m.QuestionsModule), outlet: 'questions'},
            {path: 'apps/dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module')
                    .then(m => m.DashboardModule)},
            {path: 'apps/news', loadChildren: () => import('./modules/news/news.module')
                    .then(m => m.NewsModule)},
            {path: 'apps/boards', loadChildren: () => import('./modules/task-manager/routing.module')
                    .then(m => m.TaskManagerRoutingModule)},
            {path: 'apps/learning', loadChildren: () => import('./modules/learning/learning.module')
                    .then(m => m.LearningModule)},
            {path: 'apps/academy', loadChildren: () => import('./modules/academy/academy.module')
                    .then(m => m.AcademyModule)},
            {path: 'apps/phonebook', loadChildren: () => import('./modules/phonebook/routing.module')
                    .then(m => m.PhonebookRoutingModule)},
            {path: 'apps/calendar', loadChildren: () => import('./modules/calendar/routing.module')
                    .then(m => m.CalendarRoutingModule)},
            {path: 'cabinet', loadChildren: () => import('./modules/cabinet/cabinet.module')
                    .then(m => m.CabinetModule)},
            {
                path: 'cabinet-company',
                loadChildren: () => import('./modules/cabinet-company/cabinet-company.module')
                    .then(m => m.CabinetCompanyModule)
            },
            {
                path: 'cabinet-radius',
                canActivate: [IsNpmGuard],
                canActivateChild: [IsNpmGuard],
                loadChildren: () => import('./modules/cabinet-radius/cabinet-radius.module')
                    .then(m => m.CabinetRadiusModule)
            },
            {path: 'apps/:appId', component: AppsComponent},
            {path: 'test-sentry', component: TestSentryComponent},
        ]
    },
    {path: 'rules', component: RulesComponent},
    {path: 'hello',  loadChildren: () => import('./modules/landing/landing.module')
            .then(m => m.LandingModule)},
    {path: 'auth', loadChildren: () => import('./modules/auth/auth.module')
            .then(m => m.AuthModule)},
    {path: '404', component: LostComponent},
    {path: '**', redirectTo: '404'},

];

const pages = [ MainComponent,  LostComponent, RulesComponent];
const components = [HeaderComponent,
    TestSentryComponent,
    AppsComponent, HorizontalSpinnerComponent, AdsComponent, EmailValidatorValueDirective];
const pipes = [];

@NgModule({
    declarations: [
        ...pages,
        ...components,
        ...pipes
    ],
    imports: [
        RouterModule.forRoot(routes, {}),
        SharedModule,
        InfiniteScrollModule,
        NgxMaskModule
    ],
    exports: [
        RouterModule
    ],
    providers: [
        HttpClient,
        IsAuthenticatedGuard,
        IsNpmGuard,
        UserInfoService,
        WebsocketService,
        {provide: 'config', useValue: chatConfig},
    ],
})
export class RoutingModule {
}
