import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {ConfigService} from './config.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {JSON} from 'ta-json';
import {map, take, tap} from 'rxjs/operators';
import {Rights, User} from '../models/user';
import * as Sentry from '@sentry/angular';
import * as moment from 'moment';
import 'moment-timezone';
import {Sections} from '../models/sections';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private SK_API_AUTH = 'currentUser.ApiAuth';
    private currentUser: User = null;
    public tariffsLoaded$ = new BehaviorSubject(null);
    private tariffsBoardsUsers = null;
    public globalRefresh$ = new Subject();
    public isNpm$ = new BehaviorSubject(null);
    public company$ = new BehaviorSubject(null);
    public openResetForm$ = new BehaviorSubject(null);
    public updateTimezone$ = new Subject();
    /** Текущий пользователь */
    currentUser$ = new BehaviorSubject<User>(null);
    timeZone$ = new BehaviorSubject(null);

    public person$ = new BehaviorSubject<User>(null);
    public rights$ = new BehaviorSubject<Rights>(null);
    private tariffsUsers = null;
    public hasAccessToMatrix = false;
    private permits= new BehaviorSubject<Sections>(null);

    getRights$ = new Subject<boolean>();

    constructor(
        readonly http: HttpClient,
        public config: ConfigService,
    ) {
    }

    public setTariffsUsers(users) {
        this.tariffsUsers = users;
    }

    public fetchAccessToMatrix() {
        https://api.development.myradius.ru/platform-matrix/api/v1/access
        this.http.get(this.config.matrixBaseUrl + '/access',
            this.authorize())
            .pipe(take(1))
            .subscribe(resp => {
                this.hasAccessToMatrix = true;
            }, error => {
                this.hasAccessToMatrix = false;
            });
    }

    public setBoardsTariffs(boardsUsers) {
        this.tariffsBoardsUsers = boardsUsers;
        this.tariffsLoaded$.next(boardsUsers);
    }

    public getSelfPaid() {
        if (this.getTariffsBoardUsers() && this.auth && this.auth.id) {
            return this.getTariffsBoardUsers().some(i => +i === +this.auth.id);
        }

        return false;
    }

    public getUserPaid(userId) {
        return this.getTariffsBoardUsers()?.some(i => i === userId);
    }

    public getTariffsBoardUsers() {
        if (this.tariffsUsers) {
            return this.tariffsUsers['boards'];
        } else {
            return null;
        }
    }

    public getTariffsUsers() {
        return this.tariffsUsers;
    }

    public get isSignedIn() {
        return !!this.auth;
    }

    public get auth() {
        if (!this.currentUser) {
            const value = window.localStorage.getItem(this.SK_API_AUTH);

            if (value) {
                this.currentUser = JSON.parse(value, User);
                this.currentUser$.next(this.currentUser);
            } else {
                this.currentUser = null;
            }
        }

        return this.currentUser;
    }

    authorize(options: {
        headers?: HttpHeaders;
        params?: HttpParams;
    } = {}) {
        if (!options.headers) {
            options.headers = new HttpHeaders();
        }
        const auth = this.auth.apiKey;
        if (auth) {
            options.headers = options.headers.set('Authorization', auth);
        }

        options['withCredentials'] = true;
        return options;
    }

    public signIn(email: string, password: string): Observable<any> {
        return this.http.post(
            `${this.config.signIn}`,
            {account: email, password, platform: 'WEB'},
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json'),
                withCredentials: true
            }
        );
    }

    public signOut(fcm_token): Observable<any> {
        const key = this.auth.apiKey;
        this.currentUser$.next(null);
        this.currentUser = null;
        this.person$.next(null);
        window.localStorage.removeItem(this.SK_API_AUTH);
        return this.http.post(
            `${this.config.signOut}`,
            {fcm_token},
            {headers: {Authorization: key}, withCredentials: true}
        );
    }

    public  signInReset(email: string) {
        return this.http.post(
            `${this.config.signReset}`,
            {account: email, platform: 'WEB'},
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json'), withCredentials: true
            });
    }

    public signInResetComplete(password: string, token: string, account: string = null): Observable<any> {
        const body = {password, cipher: token, platform: 'WEB'};
        if (account) {
            body['account'] = account;
        }
        return this.http.post(
            `${this.config.resetComplete}`,
            body,
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json'),
                withCredentials: true
            });
    }

    signUpComplete(password: string, token: string): Observable<any> {
        return this.http.post(
            `${this.config.signUpComplete}`,
            {password, token},
            {
                headers: new HttpHeaders().set('Content-Type', 'application/json'),
                withCredentials: true
            });
    }

    public createCompany(data) {
        return this.http.post(
            `${this.config.authorization}sign-up-complete/`,
            data,
        ).pipe(
            map(res => res['payload'])
        );
    }

    public sendPinToEmail(email) {
        return this.http.post(
            `${this.config.authorization}sign-up/`,
            {account: email},
        ).pipe(
            map(res => res['payload'])
        );
    }

    public checkPassword(password) {
        return this.http.post(
            `${this.config.authorization}check-my-password/`,
            {password},
            {headers: {Authorization: this.auth.apiKey}}
        ).pipe(
            map(res => res['payload'])
        );
    }

    public changePassword(password, newPassword) {
        return this.http.post(
            `${this.config.authorization}change-my-password/`,
            {
                password,
                new_password: newPassword},
            {headers: {Authorization: this.auth.apiKey}}
        ).pipe(
            map(res => res['payload'])
        );
    }

    public deleteSelf(id) {
        return this.http.delete(
            `${this.config.users}/${id}/`,
            {headers: {Authorization: this.auth.apiKey}}
        ).pipe(
            map(res => res)
        );
    }

    public checkEmailPinCode(email, pin) {
        return this.http.get(
           `${this.config.authorizationInvites}?account=${email.replace(/\+/g,'%2B')}&cipher=${pin}`
        ).pipe(
            map(res => res)
        );
    }

    public checkEmail(data) {
        return this.http.post(
            `${this.config.checkEmail}`,
            data
        ).pipe(
            map(res => res['payload'])
        );
    }

    public sendPartnerNumberEmail(data) {
        return this.http.post(
            `${this.config.sendEmail}`,
            data
        ).pipe(
            map(res => res['payload'])
        );
    }

    public setNewAvatar(photo) {
        const user = this.currentUser;
        user.photo = photo;
        this.currentUser$.next(user);
        window.localStorage.setItem(this.SK_API_AUTH, JSON.stringify(user));
    }

    public setAuth(value) {
        if (value) {
            window.localStorage.setItem(this.SK_API_AUTH, JSON.stringify(value));
            this.currentUser$.next(JSON.deserialize(value, User));
            this.currentUser = this.currentUser$.value;
        } else {
            window.localStorage.removeItem(this.SK_API_AUTH);
            this.currentUser$.next(null);
            this.currentUser = null;
            this.person$.next(null)
        }
    }

    public createInviteEmail(data) {
        return this.http.post(
            `${this.config.authEmailInvite}`,
            data,
            this.authorize()
        ).pipe(map(res => res['payload']));
    }

    public deleteInviteEmail(data) {
        return this.http.delete(
            `${this.config.authEmailInvite}?user_id=${data.userId}&account=${data.account}`,
            this.authorize()
        );
    }

    public deleteEmail(data) {
        return this.http.delete(
            `${this.config.authUpdateEmail}?user_id=${data.userId}&account=${data.account}`,
            this.authorize()
        );
    }

    public updateVerifiedEmail(data){
        return this.http.put(
            `${this.config.authUpdateEmail}`,
            data,
            this.authorize()
        ).pipe(map(res => res['payload']));
    }

    public updateInviteEmail(data){
        return this.http.put(
            `${this.config.authEmailInvite}`,
            data,
            this.authorize()
        ).pipe(map(res => res['payload'],));
    }

    public acceptInvite(data){
        return this.http.post(
            `${this.config.authEmailAcceptInvite}`, data).pipe(map(res => res['payload']));
    }

    /** Получает данные с бэка про текущего пользователя и пихает их в observables */
    fetchUserInfo() {
        return this.http.get(this.config.usersApiInfoUrl, this.authorize())
            .pipe(tap(data => {
                const person = JSON.deserialize<User>(data['payload'], User);
                const rights = JSON.deserialize<Rights>(data['rights'], Rights);
                this.person$.next(person);
                this.rights$.next(rights);
                this.currentUser$.next(person);

                if(person.is_auto_timezone) {
                    const guessTz = moment.tz.guess();
                    this.timeZone$.next(guessTz);
                } else {
                    this.timeZone$.next(person.timezone);
                }

                Sentry.getCurrentScope().setUser({
                    id: person?.id,
                    username: person?.shortName,
                    email: person?.accounts?.find(account => account.isNotifiable)?.account || undefined,
                });

                if (person['company']) {
                    const isNpm = person['company']['is_platform_owner_company'];
                    this.company$.next(person['company']);
                    this.isNpm$.next(isNpm);
                }

                const avatar = this.config.getUserAvatarByUser(person, 'small', true);
                this.setNewAvatar(avatar);

                this.fetchAccessToMatrix();
                
                if (!person.timezone) {
                    const data = JSON.serialize(person);

                    let status = null;

                    if (person.status) {
                        status = {
                            type: person.status.type,
                            date_start: person.status.dateStart ?
                                moment(person.status.dateStart).utc(true) : null,
                            date_end: person.status.dateEnd ?
                                moment(person.status.dateEnd).utc(true) : null
                        };
                    }

                    data['is_auto_timezone'] = true;
                    data['timezone'] = moment.tz.guess();
                    data['status'] = status;

                    this.updateTimezone$.next(data);
                    this.getRights$.next(true);
                }
            }));
    }
}
